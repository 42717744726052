
import { DestinationsListRequest } from "@/services/api/ApiDestination";
import { ListItineraries } from "@/services/api/ApiItineraries";
import { IDestination } from "@/services/models/destinations";
import {
  IItineraryList,
  IItineraryListPagination
} from "@/services/models/itineraries";
import { notificationError } from "@/utils/NotificationUtils";
import { Vue, Component, Watch } from "vue-property-decorator";
import ItinerariesForm from "./itineraries-form/ItinerariesForm.vue";

@Component({ components: { ItinerariesForm } })
export default class Itineraries extends Vue {
  loading = false;
  openModal = false;
  items: IItineraryList[] = [];
  perPage = 10;
  currentPage = 1;
  totalRows = 0;
  pages = 1;
  filter = "";
  fields: { key: string; label: string }[] = [
    { key: "id", label: `${this.$t("itineraries.fields.id")}` },
    {
      key: "driver_name",
      label: `${this.$t("itineraries.fields.driver_name")}`
    },
    { key: "actions", label: `${this.$t("itineraries.fields.actions")}` }
  ];
  pageOptions = [10, 25, 50, 100];
  destinations: IDestination[] = [];
  selectedToEdit: IItineraryList = {} as IItineraryList;

  @Watch("perPage")
  async onPerPageChange() {
    await this.list();
  }
  @Watch("currentPage")
  async onCurrentPageChange() {
    await this.list();
  }

  showModal() {
    this.openModal = true;
  }

  closeModal() {
    this.openModal = false;
  }

  async mounted() {
    await this.list();
  }

  async list(): Promise<void> {
    this.loading = true;
    try {
      const response: IItineraryListPagination = await ListItineraries(
        this.currentPage,
        this.perPage
      );
      this.items = response.data;
      this.totalRows = response.total;
      this.pages = response.last_page;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  reloadPage(): void {
    this.list();
  }

  async showEditModal(data: IItineraryList): Promise<void> {
    try {
      console.log(data);
      this.selectedToEdit = data;
      this.openModal = true;
    } catch (error) {
      console.error(error);
      notificationError(
        `${this.$t("itineraries.messages.error.message.searchItinerarie")}`
      );
    }
  }
}
