
import { DestinationsListRequest } from "@/services/api/ApiDestination";
import {
  DriverFetchOneRequest,
  DriversList,
  DriversListWithoutitineraries
} from "@/services/api/ApiDrivers";
import {
  ItineraryTravelDelete,
  ShowItineraryByDriverId,
  StoreItinerary,
  UpdateItinerary
} from "@/services/api/ApiItineraries";
import { RegionalsList } from "@/services/api/ApiRegionals";
import { IDriver } from "@/services/models/driver";
import {
  IItineraryList,
  IItineraryStore,
  ITravel
} from "@/services/models/itineraries";
import ITravelStore from "@/services/models/itineraries/ITravelStore";
import { IRegional, IRegionalListRequest } from "@/services/models/regionals";
import { TrasformDriverShowOneToIDriver } from "@/services/transform/DriverDataTransform";
import { notificationSuccess } from "@/utils/NotificationUtils";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";

@Component({ components: { draggable } })
export default class Itineraries extends Vue {
  loading = false;
  @Prop() open_modal!: boolean;
  @Prop() edit_data!: IItineraryList;
  selectedDestinations: ITravelStore[] = [];
  selectedDriver?: number = undefined;
  destinationsList: IRegional[] = [];
  driversList: IDriver[] = [];
  selectDriverEnable = true;
  travelOptions: ITravelStore[] = [];
  @Watch("open_modal", { deep: true })
  async loadData(): Promise<void> {
    if (this.open_modal) {
      await Promise.all([this.listDestinations(), this.listDrivers()]);
    } else {
      this.selectedDestinations = [];
    }
  }

  @Watch("edit_data")
  async loadEditData(): Promise<void> {
    if (this.edit_data.id) {
      this.selectDriverEnable = false;
      const driver = TrasformDriverShowOneToIDriver(
        await DriverFetchOneRequest(this.edit_data.driver_id)
      );
      // const itinerary = await ShowItineraryByDriverId(this.edit_data.driver_id);
      this.driversList = [driver];
      this.selectedDriver = driver.id;
      console.log(this.edit_data);
      this.selectedDestinations = this.edit_data.travels;
    }
    return;
  }

  async listDestinations(): Promise<void> {
    this.loading = true;
    try {
      const response: IRegionalListRequest = await RegionalsList(1, 999);
      this.travelOptions = this.transformRegionalToTravelOptions(response.data);
      this.destinationsList = response.data;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  transformRegionalToTravelOptions(data: IRegional[]): ITravelStore[] {
    const travelOptions: ITravelStore[] = [];
    data.forEach((regional) => {
      travelOptions.push({
        destination_id: regional.id,
        destination_name: regional.name,
        recurrent: true
      });
    });

    return travelOptions;
  }

  async listDrivers(): Promise<void> {
    this.loading = true;
    try {
      const response = await DriversListWithoutitineraries(1, 999);
      this.driversList = response.data;
      if (response.data.length > 0) {
        this.selectDriverEnable = true;
        this.selectedDriver = undefined;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  close(): void {
    this.$emit("reload_page");
    this.$emit("close_modal");
    this.$v.$reset();
    this.selectedDestinations = [];
    this.selectedDriver = undefined;
    this.travelOptions = [];
  }

  copyItem(data: any): void {
    return { ...data };
  }

  // transformRegionalToTravel(destinations: IRegional[]): ITravelStore[] {
  //   const travels: ITravelStore[] = [];
  //   destinations.forEach((element: IRegional, index: number) => {
  //     travels.push({
  //       index: (index + 1) * 5,
  //       destination_id: element.id as number,
  //       recurrent: !!element.recurrent
  //     });
  //   });

  //   return travels;
  // }
  async deleteItem(index: number) {
    try {
      const item = this.selectedDestinations[index];
      if (item.id) {
        const resp = await ItineraryTravelDelete(item.id);
      }

      this.selectedDestinations.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  genarateIndexes(travels: ITravelStore[]): ITravelStore[] {
    travels.forEach((travel, index) => {
      travel.index = (index + 1) * 5;
    });
    return travels;
  }

  async save(): Promise<void> {
    try {
      const travels = this.genarateIndexes(this.selectedDestinations);

      const itinerary: IItineraryStore = {
        driver_id: this.selectedDriver!,
        travels
      };
      console.log(JSON.stringify(itinerary));
      const resp = this.selectDriverEnable
        ? await StoreItinerary(itinerary)
        : await UpdateItinerary(this.edit_data.id, itinerary);

      notificationSuccess(
        this.selectDriverEnable
          ? `${this.$t("users.messages.success.message.create")}`
          : `${this.$t("users.messages.success.message.update")}`
      );
      this.close();
    } catch (error) {
      console.log(error);
    }
  }
}
