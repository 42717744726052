var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"no-close-on-backdrop":"","no-close-on-esc":"","hide-header-close":"","title":_vm.edit_data.id
      ? _vm.$t('itineraries.modal.form.title.update')
      : _vm.$t('itineraries.modal.form.title.create'),"size":"xl"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("itineraries.modal.form.cancel"))+" ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("itineraries.modal.form.save"))+" ")])]},proxy:true}]),model:{value:(_vm.open_modal),callback:function ($$v) {_vm.open_modal=$$v},expression:"open_modal"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('itineraries.fields.driver_name')}},[_c('b-form-select',{attrs:{"size":"sm","value-field":"id","text-field":"full_name","disabled":!_vm.selectDriverEnable},model:{value:(_vm.selectedDriver),callback:function ($$v) {_vm.selectedDriver=$$v},expression:"selectedDriver"}},[_c('b-form-select-option',{attrs:{"value":undefined}},[_vm._v(" Selecione uma opção ")]),_vm._l((_vm.driversList),function(option,indexOpt){return _c('b-form-select-option',{key:indexOpt,attrs:{"value":option.id}},[_vm._v(" "+_vm._s(option.full_name)+" ")])})],2)],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('h3',[_vm._v("Lista de Destinos")]),_c('draggable',{staticClass:"dragArea list-group",attrs:{"list":_vm.travelOptions,"clone":_vm.copyItem,"group":{
            name: 'destinations',
            pull: 'clone',
            put: false
          }}},_vm._l((_vm.travelOptions),function(element,index){return _c('div',{key:index,staticClass:"list-group-item"},[_vm._v(" "+_vm._s(element.destination_name)+" ")])}),0)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('h3',[_vm._v("Destinos selecionados")]),_c('draggable',{staticClass:"dragArea list-group",attrs:{"list":_vm.selectedDestinations,"group":{
            name: 'destinations',
            pull: 'remove',
            put: true
          }}},_vm._l((_vm.selectedDestinations),function(element,index){return _c('div',{key:element.index,staticClass:"list-group-item drag-list d-flex justify-content-between"},[_vm._v(" "+_vm._s(index + 1)+"-"+_vm._s(element.destination_name)+" "),_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{staticClass:"mr-2",attrs:{"name":("checkbox-" + index)},model:{value:(element.recurrent),callback:function ($$v) {_vm.$set(element, "recurrent", $$v)},expression:"element.recurrent"}},[_vm._v(" Recorrente ")]),_c('b-button',{attrs:{"variant":"outline-danger","size":"sm mx-1","title":_vm.$t('itineraries.buttons.edit')},on:{"click":function($event){return _vm.deleteItem(index)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1)],1)])}),0)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }