import {
  IItineraryList,
  IItineraryListPagination,
  IItineraryStore
} from "../models/itineraries";
import ApiBase from "./ApiBase";

const ITINERARIES = "/dash/admin/itineraries";

export const ListItineraries = async (
  page: number,
  offset: number
): Promise<IItineraryListPagination> => {
  const resp = await ApiBase.get(
    `${ITINERARIES}?page=${page}&offset=${offset}`
  );
  return resp?.data as IItineraryListPagination;
};

export const StoreItinerary = async (data: IItineraryStore): Promise<void> => {
  const resp = await ApiBase.post(`${ITINERARIES}`, data);
  return;
};

export const UpdateItinerary = async (
  id: number,
  data: IItineraryStore
): Promise<void> => {
  const rest = await ApiBase.put(`${ITINERARIES}/${id}`, data);
  return;
};

export const ShowItineraryByDriverId = async (
  driver_id: number
): Promise<IItineraryList> => {
  const resp = await ApiBase.get(`${ITINERARIES}/bydriver/${driver_id}`);
  return resp.data;
};

export const ItineraryTravelDelete = async (
  travel_id: number
): Promise<void> => {
  const resp = await ApiBase.patch(
    `${ITINERARIES}/travels/${travel_id}/inativate`
  );

  return;
};
